.re-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .re-modal-content {
    background: white;
    /* padding: 20px; */
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
    width: 350px;
    text-align: center;
    position: relative;
    z-index: 1001;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  
  .re-close-button {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 35px;
    cursor: pointer;
    color:red;
  }
  
  .re-input-common {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .re-cmn-btn {
    background-color: #086485;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    /* border-radius: 20px; */
  }
  
  .re-cmn-btn:hover {
    background-color:  #073e52;
  }
  