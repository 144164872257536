@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}
.loader1-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
	z-index: 9999; /* Ensure the loader container is on top of other elements */
  }
  .loader2-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
	z-index: 9999; /* Ensure the loader container is on top of other elements */
  }
.heading1 {
	font-weight: bold;
	margin: 0;
}

.para {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.anchor {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
	cursor: pointer;
}
.anchor:hover {
	color: #173ba9;
	font-size: 14px;
	margin: 15px 0;
}

.cmn-btn {
	border-radius: 20px;
	/* border: 1px solid #11acc1; */
	background-color: #065465;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.cmn-btn:active {
	transform: scale(0.95);
}

.cmn-btn:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.form1 {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.input-common {
	background-color: #eee;
	border: none;
	border-radius: 40px;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.containerA {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.form-containerA {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-containerA {
	left: 0;
	width: 50%;
	z-index: 2;
}

.containerA.right-panel-active .sign-in-containerA {
	transform: translateX(100%);
}

.sign-up-containerA {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.containerA.right-panel-active .sign-up-containerA {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-containerA {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.containerA.right-panel-active .overlay-containerA{
	transform: translateX(-100%);
}

.overlay {
	/* background: #08478e; */
	/* background: -webkit-linear-gradient(to right, #ffbf2b, #FF416C); */
	background: linear-gradient(to right, #323c46, #047bb1);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.containerA.right-panel-active .overlay {
  	transform: translateX(50%);
}
.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.containerA.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.containerA.right-panel-active .overlay-right {
	transform: translateX(20%);
}


.select-role{
    background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
    position: relative;
}
.select-role select {
    position: absolute;
    top: -100%; 
    width: 100%;
}
