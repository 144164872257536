.home_heading {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: #0A372D;
  margin-top: 40px;
}

.under {
  position: relative;
  display: inline-block;
  color:#6f1111;
  padding-bottom: 10px;
  text-align: center;
  /* margin: 0 auto;  */
}
.under:hover {
  color: black;
}

.under::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 4px; 
  background-color: #e81616; 
  transform: translateX(-50%);
  transition: width 0.3s ease-out;
}

.under:hover::before {
  width: 100%;
}
.under {
  text-align: center;
  margin-bottom: -5px;
}

.tile-image {
  height: 10rem;
  background-color: #cbd5e0;
  border-radius: 0.5rem;
}

.tile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}

.tile-heading {
  font-size: 1.5rem;
  font-weight: 600;
}

.tile-link {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
  background-color: #cbd5e0;
  font-size: 0.75rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #2d3748;
}

.card-body {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 8px;
  border: none;
}

.card {
  border: none;
}

.anime-2 {
  transition: transform 0.3s ease;
}

.anime-2:hover {
  transform: scale(0.9);
}

img {
  object-fit: contain;
}

h4 {
  font-size: 15px;
}

.home_card {
  width: 240px;
}

.home_row {
  /* row-gap: 50px; */
  /* column-gap: 20px; */
  justify-self: center;
}

.mt-5 {
  margin-top: 0px !important;
}

.market {
  background: transparent;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-bottom: 40px; */
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center; 
}

.market:hover {
  border-radius: 5px;
  border-color: #0A372D;
}

.market h2 {
  text-align: center; /* Center the heading */
}

.sales {
  background: transparent;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-bottom: 40px; */
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center; /* Center the content */
}

.sales:hover {
  border-radius: 5px;
}

.sales h2 {
  text-align: center; /* Center the heading */
}

.oso {
  background: transparent;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-bottom: 20px; */
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center; 
}

.oso:hover {
  border-radius: 5px;
}

.oso h2 {
  text-align: center; 
}

.center-card {
  display: flex;
  justify-content: center;
}

.col-xl-4 {
  width: 27%;
}

.main-cent {
  display: flex;
  justify-content: center;
}

/* HomePageGrid.css */

.container.main-cent {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.cent {
  width: 100%;
}

.col-xl-3{
  width: 18%;
}


.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.col-seven {
  flex: 0 0 calc(100% / 7);
  max-width: calc(100% / 7);
}

.mt-3 {
  margin-top: 1rem !important;
}

.anime-2 {
  transition: all 0.2s ease;
}

/* .card.home_card {
  width: 100%;
  max-width: 200px;
} */
