.profile-modal-overlay {
  position: fixed;
  top: 100px;
  right:500px;
  width: 400px;
  /* height: 100%; */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(5px);
}

.profile-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 600px;
  max-width: 100%;
  position: relative;
}

.profile-modal-header {
  display: flex;
  justify-content: end;
  align-items: right;
  margin-bottom: 20px;
}

.profile-close-icon {
  cursor: pointer;
  color: red;
  font-size: large;
}
.user-profile-image{
  position: relative;
}

.edit-delete-icons {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
}

.edit-delete-icons .icon {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  padding: 10px;
}
.edit-delete-icons .icon:hover{
  background-color: rgba(9, 86, 112, 0.795);
}
.profile-form-group {
  margin-bottom: 20px;
}

.profile-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.profile-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.profile-picture-preview {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.profile-update-btn {
  background-color: #0b6881;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  color: white;
}

.profile-update-btn:hover {
  background-color: #035063;
}

.edit-user-profile-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.update-pwd{
  background-color: rgb(12, 111, 133) !important;
  width: 100%;
  padding: 8px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  color:#fff
}
.update-pwd:hover{
  background-color: rgb(11, 87, 104) !important;
}