@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  /* margin: 0; */
  /* padding: 0; */
  box-sizing: border-box;
  /* font-family: 'Poppins',sans-serif; */
  /* text-decoration: none; */
}
footer{
  width: 100%;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  /* background: #03254c; */
  background: #020d1a;

}
footer .content{
  /* max-width: 1350px; */
  margin-top: 40px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
footer .content p,a{
  color: #fff;
}
.foot-logo{
    height: 40px;
    margin-bottom: 5px;
}
footer .content .box{
  width: 33%;
  transition: all 0.4s ease;
}
footer .content .topic{
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;

}
.contact-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.phone, .email {
  margin-right: 30px; 
}
footer .content p{
  text-align: justify;
}
/* footer .content .lower .topic{
  margin: 24px 0 5px 0;
} */
footer .content .lower i{
  padding-right: 16px;
}
footer .content .middle{
  padding-left: 80px;
}
footer .content .middle a{
  line-height: 32px;
}
footer .content .right input[type="text"]{
  height: 45px;
  width: 100%;
  outline: none;
  color: #d9d9d9;
  background: #000;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 17px;
  border: 2px solid #222222;
}
footer .content .right input[type="submit"]{
  height: 42px;
  width: 100%;
  font-size: 18px;
  color:  #9a9a9a;
  background: none;
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 12px;
  border: 2px solid #989898;
  transition: all 0.1s ease-in-out;
}
.content .right input[type="submit"]:hover{
  background: #c5c5c5;
  color: #03254c;

}
footer .content .media-icons a{
  font-size: 16px;
  height: 45px;
  width: 45px;
  display: inline-block;
  text-align: center;
  line-height: 43px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  margin: 30px 5px 0 0;
  transition: all 0.3s ease;
}
.content .media-icons a:hover{
  border-color: #eb2f06;
}
footer .bottom{
  width: 100%;
  text-align: right;
  color: #d9d9d9;
  padding: 0 40px 5px 0;
}
footer .bottom a{
  color: #eb2f06;
}
footer a{
  transition: all 0.3s ease;
}
footer a:hover{
  color: #eb2f06;
}
@media (max-width:1100px) {
  footer .content .middle{
    padding-left: 50px;
  }
}
@media (max-width:950px){
  footer .content .box{
    width: 50%;
  }
  .content .right{
    margin-top: 40px;
  }
}
@media (max-width:560px){
  footer{
    position: relative;
  }
  footer .content .box{
    width: 100%;
    margin-top: 30px;
  }
  footer .content .middle{
    padding-left: 0;
  }
}

footer hr {
    border: none;
    height: 1px;
    background-color: #ccc; 
    margin: 10px auto; 
    width: 100%; 
  }