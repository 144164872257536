/* Container for the cards */
.drive-main-container {
  display: flex;
  column-gap: 100px;
  justify-content: center;
  align-items: center;
  padding-top: 110px;
}

/* Card structure */
.drive-main-card {
  width: 220px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

/* Image inside the card */
.drive-main-card-image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 15px;
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

/* Card title styling */
.drive-main-card-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Hover effect on the card */
.drive-main-card:hover .drive-main-card-image {
  transform: scale(1.2); /* Zoom in the image */
}
