.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0 15px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    
  }
  
  .navbar-left .logo {
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
  }

  .navbar-left{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #252222;
  /* background-image: url('/public/images/lap.jpg'); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 0 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  /* background: transparent; */
}

.navbar-left .logo {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

.navbar-left{
display: flex;
justify-content: space-between;
align-items: center;

}

.menu{
  font-size: 40px;
  color: #fff;
}

.navbar-right ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
  display: flex;
}

.navbar-right li {
  margin-left: 20px;
}

.navbar-right li a {
  color: #ffff;
  text-decoration: none;
}

.navbar-left .logo {
color: #fff;
text-decoration: none;
font-size: 24px;
font-weight: bold;
}

.navbar-left{
display: flex;
align-items: center;
justify-content: flex-start;

}

.menu{
font-size: 40px;
color: #fff;
}

.navbar-right ul {
list-style-type: none;
margin: 0;
padding: 10px;
display: flex;
}

.navbar-right li {
margin-left: 20px;
}

.navbar-right li a {
color: #464646;
text-decoration: none;
}

/* 
.search-container{
  color: #ffff;
background: #252222;
height: 40px;
width: 100%;
border-radius: 30px;
padding: 10px 20px;
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;
transition: 0.8s;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
transition: transform 0.3s ease;
} */

/* .search-container:hover{
  transform: scale(1.1);
} */



/* .search-container .search-input{
background: transparent;
border: none;
outline:none;
width: 200px;
font-weight: 500;
font-size: 16px;
transition: 0.8s;

}


.search-container .search-btn .fas{
color: #5cbdbb;
} */


:root {
--accent-color: #fff;
--gradient-color: #FBFBFB;
}


.sidebar{
  position: fixed;
  width: 255px;
  left: -10px;
  height: 100%;
  background-color: #fff;
  transition: all .5s ease;
  }
.sidebar header{
font-size: 28px;
color: #353535;
line-height: 70px;
text-align: center;
background-color: #fff;
user-select: none;
font-family: 'Lato', sans-serif;
}
.sidebar a{
display: block;
/* height: 65px; */
width: 100%;
color: #353535;
line-height: 50px;
padding-left: 30px;
box-sizing: border-box;
text-decoration: none;
font-family: 'Lato', sans-serif;
transition: all .5s ease;
}
 .navbar .sidebar .menu_links.active,.menu_links:hover{
  border-left: 5px solid #ed1e1eb4;
  color: #fff;
  background: #7c7d7e;
}


.sidebar a i{
font-size: 13px;
margin-right: 16px;
}
.sidebar a span{
font-size: 13px;
letter-spacing: 1px;
/* text-transform: uppercase; */
}
#check{
display: none;
}
label #btn,label #cancel{
position: absolute;
left: 5px;
cursor: pointer;
color: #d6adff;
border-radius: 5px;
margin: 15px 30px;
font-size: 29px;
background-color: #e8d1ff;
box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
inset -7px -7px 10px 0px rgba(0,0,0,.1),
3.5px 3.5px 20px 0px rgba(0,0,0,.1),
2px 2px 5px 0px rgba(0,0,0,.1);
height: 45px;
width: 45px;
text-align: center;
text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
line-height: 45px;
transition: all .5s ease;
}
label #cancel{
opacity: 0;
visibility: hidden;
}
#check:checked ~ .sidebar{
left: 0;
}
#check:checked ~ label #btn{
margin-left: 245px;
opacity: 0;
visibility: hidden;
}
#check:checked ~ label #cancel{
margin-left: 245px;
opacity: 1;
visibility: visible;
}
@media(max-width : 860px){
.sidebar{
height: auto;
width: 70px;
left: 0;
margin: 100px 0;
}
header,#btn,#cancel{
display: none;
}
span{
position: absolute;
margin-left: 23px;
opacity: 0;
visibility: hidden;
}
.sidebar a{
height: 60px;

}
.sidebar a i{
margin-left: -10px;
}
a:hover {
width: 200px;
background: inherit;
}
.sidebar a:hover span{
opacity: 1;
visibility: visible;
}
}

.sidebar > a.active,.sidebar > a:hover:nth-child(even) {
--accent-color: #52d6f4;
--gradient-color: #c1b1f7;
}
.sidebar a.active,.sidebar > a:hover:nth-child(odd) {
--accent-color: #c1b1f7;
--gradient-color: #A890FE;
}


.frame {
width: 50%;
height: 30%;
margin: auto;
text-align: center;
}

/* .BUtton{
  background-color: #4a4a4a; 
  border: none;
  color: white;
  border-radius: 10px;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.BUtton:hover{
  background-color: #807f7f;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
} */


