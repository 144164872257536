.news_heading {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: #0a372d;
  margin-top: 20px;
}
.news_grid {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;
  padding-left: 50px !important;
  padding-right: 90px !important;
}

.card-hover {
  transition: transform 0.3s;
}

.card-hover:hover {
  transform: scale(1.05);
}

.card-h4{
  font-size: 20px;
  padding-top: 10px;
}

.Ncard-body{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 8px;
  border: none;
  padding:0;
  width:310px;
}
.image-container {
  overflow: hidden;
  border-radius: 5px 5px 0 0; /* Added border-radius for top corners */
}
.loader-con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8); /* optional, for a slight overlay effect */
}
