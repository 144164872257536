.user-details-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-85%, 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-profile-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 300px;
  position: relative;
  height: 320px;
}

.user-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.user-info {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-name {
  margin: 0;
  font-size: 18px;
}

.user-email {
  margin: 0;
  color: #666;
}

.user-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bUtton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.bUtton:focus {
  outline: none;
}

.edit-profile {
  background-color: #0b6881;
  color: white;
}

.edit-profile:hover {
  background-color: #035063;
}

.logout {
  background-color: #f44336;
  color: white;
}

.logout:hover {
  background-color: #cb2b28;
}

hr {
  width: 100%;
  border: none;
  border-top: 2px solid #ddd;
  margin: 20px 0;
}

.loader {
  position: absolute;
	top: 50%;
	left: 50%;
  transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5); 
	z-index: 9999;
}
