.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .not-found-image {
    max-width: 300px;
    margin-bottom: 20px;
  }
  
  .not-found-text {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .not-found-link {
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .not-found-link:hover {
    color: #0056b3;
  }
  