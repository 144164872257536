/* General Navbar Styles */
.drive-nav {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: #242526;
}

.drive-wrapper {
  height: 70px;
  line-height: 70px;
  margin: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Logo styles */
.logo-link {
  margin-left: 20px;
}

.logo-img {
  height: 40px;
}

/* Navbar links */
.drive-nav-links {
  display: inline-flex;
  margin-bottom: 0;
}

.nav-item {
  list-style: none;
}

.nav-link {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 17px;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: red;
}
.active-link{
  color: red;
  font-weight: bold;
}

.nav-link:focus,
.nav-link:active {
  outline: none;
  color:red;
}

/* Dropdown Menu */
.drive-drop-menu {
  position: absolute;
  background: #242526;
  padding: 10px;
  line-height: 45px;
  top: 70px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.nav-item:hover .drive-drop-menu {
  opacity: 1;
  visibility: visible;
  top: 60px;
}

/* Dropdown links */
.drive-drop-item {
  list-style: none;
}

.drive-drop-link {
  color: #f2f2f2;
  text-decoration: none;
  padding: 10px 20px;
  display: block;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.drive-drop-link:hover {
  background: #3A3B3C;
}

/* Nested Dropdown */
.drive-nested-dropdown {
  position: absolute;
  background: #333;
  top: 0;
  left: -200px;
  width: 200px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.drive-drop-menu li:hover .drive-nested-dropdown {
  opacity: 1;
  visibility: visible;
}

.drive-nested-dropdown li a {
  color: #d9d9d9;
  text-decoration: none;
  padding: 10px;
  display: block;
  list-style: none;
}

/* .active-link {
  color: red;
  font-weight: bold; /* 
} */

.drive-nested-dropdown li a:hover {
  color: red;
}

/* Desktop items */
.drive-desktop-item {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 17px;
  padding: 9px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.drive-desktop-item:hover {
  /* background: #3A3B3C; */
  color: red;
}


/* Mega Menu */
.mega-menu {
  position: absolute;
  background: #242526;
  padding: 20px;
  top: 70px;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust column count as needed */
  gap: 20px;
  z-index: 999;
}

.mega-menu-item {
  list-style: none;
}

.mega-menu-link {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 16px;
  padding: 0px 15px;
  display: block;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.mega-menu-link:hover {
  background: #3A3B3C;
  color: red;
}

.nav-item.mega-menu-parent:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  /* top: 150px; */
}

/* Styles for nested dropdown */
.drive-drop-menu li.mega-menu-parent:hover .mega-menu {
  opacity: 1;
  visibility: visible;
}
/* Specific styles for the "HO-Marketing For Everything" mega menu */
.ho-marketing-menu:hover .ho-marketing-mega-menu {
  opacity: 1;
  visibility: visible;
  top: 10px;
  left: -1000px; 
}
.ho-marketing-menu {
  position: relative;
  z-index: 999; /* Keeps the menu above the other dropdowns */
}

.ho-marketing-mega-menu {
  position: absolute;
  background: #242526;
  padding: 10px;
  width: 1000px; /* Adjust the width as needed */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust column count as needed */
  gap: 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  left: -100%;
}

/* Links inside "HO-Marketing For Everything" mega menu */
.ho-marketing-mega-menu .mega-menu-link {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 16px;
  padding: 5px 15px;
  display: block;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.ho-marketing-mega-menu .mega-menu-link:hover {
  background: #3A3B3C;
  color: red;
}
